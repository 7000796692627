import { call, put, takeLatest, delay } from 'redux-saga/effects'
import { endFetch, startFetch } from '../common/reducer'
import { searchSuccess, sear, postSuccess, postFailure } from './reducer'
import axios from 'axios'
import { config } from 'src/config'
import { v4 as uuidV4 } from 'uuid'
import { fetchTags, tagsSuccess } from '../tags/reducer'
import { getTags } from '../actions/tags'

function* createPost({ payload }) {
  const { isUpdatePost } = payload
  yield put(startFetch())
  //upload image using presigned url

  //request presignedURl
  let intialPayload = payload.post
  try {
    //get the image from the payload
    let finalPayload = []

    for (const dataVal of intialPayload) {
      if (Object.keys(dataVal)[0] === 'IMAGE' && typeof Object.values(dataVal)[0] !== 'string') {
        const keyFile = uuidV4()
        const responseForUrl = yield call(
          axios.post,
          config.GET_PRESIGNED_URL,
          { key: keyFile },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('token')}`, // Include the authorization token in the headers
            },
          },
        )
        const url = responseForUrl?.data?.uploadUrl
        yield call(axios.put, url, dataVal?.IMAGE)
        finalPayload.push({
          IMAGE: keyFile,
        })
      } else {
        finalPayload.push(dataVal)
      }
    }

    if (isUpdatePost) {
      yield call(
        axios.put,
        config.UPDATE_POST + `/${payload.id}`,
        {
          postData: finalPayload,
          tags: payload.tags.map((data) => data.label),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Include the authorization token in the headers
          },
        },
      )
    } else {
      yield call(
        axios.post,
        config.CREATE_POST,
        {
          postData: finalPayload,
          tags: payload.tags.map((data) => data.label),
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`, // Include the authorization token in the headers
          },
        },
      )
    }

    yield put(endFetch())
    yield put(postSuccess())
  } catch (error) {
    yield put(endFetch())
    yield put(postFailure())
  }
}

function* fetchPosts({ payload }) {
  yield put(startFetch())
  try {
    const queryString = `searchString=${payload.searchString}&page=${payload.page}`
    const response = yield call(axios.get, `${config.SEARCH_POST}?${queryString}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`, // Include the authorization token in the headers
      },
    })
    yield put({
      type: 'post/searchPostSuccess',
      payload: response?.data,
    })
    yield put(endFetch())
  } catch (error) {
    yield put({
      type: 'post/searchPostFailure',
    })
    yield put(endFetch())
  }
}

function* adminPost({ payload }) {
  yield put(startFetch())
  try {
    const queryString = `lastEvaluatedKey=${payload.lastEvaluatedKey}`
    const response = yield call(axios.get, `${config.SEARCH_POST_ADMIN}?${queryString}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`, // Include the authorization token in the headers
      },
    })
    yield put({
      type: 'post/adminPostSuccess',
      payload: response?.data,
    })
    yield put(endFetch())
  } catch (error) {
    yield put({
      type: 'post/adminPostFailure',
    })
    yield put(endFetch())
  }
}

function* approvePost({ payload }) {
  yield put(startFetch())
  try {
    yield call(
      axios.put,
      `${config.APPROVE_POST}/${payload.dynamoId}`,
      { status: 'APPROVED' },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Include the authorization token in the headers
        },
      },
    )
    yield put({
      type: 'post/approveSuccess',
    })

    yield put({
      type: 'posts/adminPost',
      payload: { lastEvaluatedKey: '' },
    })
    yield put(endFetch())
  } catch (error) {
    yield put(endFetch())
    yield call({
      type: 'post/approveFailure',
    })
  }
}

function* fetchPostById({ payload, params }) {
  yield put(startFetch())
  let tags = []
  try {
    if (params.type !== 'CLIENT') {
      const queryString = `search=${payload.search}&lastEvaluatedKey=${payload.lastEvaluatedKey}`
      tags = yield call(axios.get, `${config.GET_TAGS}?${queryString}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`, // Include the authorization token in the headers
        },
      })
    }

    const response = yield call(
      axios.get,
      config.GET_POST_BY_ID +
        `/${payload.id}?ip=${params?.ip}&language=${'ENGLISH'}&type=${params.type}`,
    )

    if (params.type !== 'CLIENT') {
      yield put(tagsSuccess(tags?.data))
    }

    yield put({
      type: 'post/fetchByIdSuccess',
      payload: response?.data?.Item,
    })
    yield put(endFetch())
  } catch (error) {
    yield put(endFetch())
    yield put({
      type: 'post/fetchByIdFailure',
    })
  }
}

function* postSaga() {
  yield takeLatest('posts/create', createPost)
  yield takeLatest('posts/adminPost', adminPost)
  yield takeLatest('post/fetchPost', fetchPosts)
  yield takeLatest('posts/approve', approvePost)
  yield takeLatest('posts/fetchPostById', fetchPostById)
}

export default postSaga
