import React, { useEffect, useMemo, useState } from 'react'
import { GoogleLogin } from '@react-oauth/google'
import ScrollToTop from 'react-scroll-to-top'
import {
  CCard,
  CCardBody,
  CCardText,
  CCardTitle,
  CCol,
  CContainer,
  CModal,
  CModalBody,
  CModalHeader,
  CNavbarBrand,
  CRow,
  CCardImage,
  CButton,
} from '@coreui/react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { jwtDecode } from 'jwt-decode'
import { fetchPosts, login } from 'src/sagas/actions/common'
import SearchBar from './SearchBar'
import { FaArrowRightLong } from 'react-icons/fa6'
import { FaCheckCircle, FaExclamationCircle, FaEye } from 'react-icons/fa'
import TableComponent from 'src/components/common/tableComponent'
import { useDebounce } from '@uidotdev/usehooks'
import { usePagination } from '@table-library/react-table-library/pagination'
import { node } from 'prop-types'
import { useNavigate } from 'react-router-dom'

const defaultLanguage = 'ENGLISH'
const limitData = 10

function HomeView() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const statusCode = useSelector((state) => state?.login?.statusCode)
  const nodes = useSelector((state) => state?.post?.searchedData?.hits || [])

  const TotalScannedCount = useSelector((state) => state?.post?.searchedData?.nbHits || 0)

  const [modalMessage, setMessageStatus] = useState(false)
  const [page, setPage] = useState(0)
  const [search, setSearch] = useState('')
  const debouncedSearchTerm = useDebounce(search, 300)

  const handleLogin = (data, status) => {
    const decodedToken = jwtDecode(data?.credential)
    dispatch(
      login({
        type: 'USER',
        email: decodedToken.email,
        name: decodedToken.name,
      }),
    )
  }

  const arrayData = useMemo(() => {
    const array = []
    const range = Math.ceil(TotalScannedCount / limitData)

    for (let i = 0; i <= range - 1; i++) {
      array.push(i + 1)
    }

    return array
  }, [TotalScannedCount])

  const scannedNodes = useMemo(() => {
    return nodes
  }, [nodes])

  const handleSearch = (e) => {
    setSearch(e.target.value)
  }

  const intialize = () => {
    dispatch({
      type: 'post/fetchByIdInitialize',
    })
    dispatch({
      type: 'user/createIntialize',
    })
  }

  useEffect(() => {
    intialize()
  }, [])

  useEffect(() => {
    dispatch(
      fetchPosts({
        searchString: debouncedSearchTerm,
        page,
      }),
    )
  }, [debouncedSearchTerm])

  const onPaginationChange = (page) => {}

  const pagination = usePagination(node, {
    state: {
      page: 0,
      size: 10,
    },
    onChange: onPaginationChange,
  })

  const COLUMNS = [
    {
      label: '',
      renderCell: (item) => (
        <CCard
          className="postCard gap-2 mt-3"
          style={{ cursor: 'pointer' }}
          onClick={() => navigate(`/posts/${item.dynamoId}`)}
        >
          <CCardBody>
            <div className="row ">
              <div className="col-md-3">
                {item?.post
                  ?.filter((data) => Object.keys(data)[0] === 'IMAGE')
                  .map((dataval, index) => (
                    <div
                      key={dataval?.IMAGE}
                      // className="image-container-home"
                    >
                      <CCardImage
                        className="card-image"
                        orientation="bottom"
                        key={index}
                        alt="Description"
                        src={dataval?.IMAGE || ''}
                      />
                    </div>
                  ))}
              </div>

              <div className="col-md-9">
                <CCardTitle className="CCardTitle">
                  {item?.post
                    ?.filter((data) => Object.keys(data)[0] === 'HEADING')
                    .map((dataval, index) => {
                      // Finding the relevant value based on specificText or falling back to English

                      if (!search) {
                        const defaultLang = dataval.HEADING.find((data) =>
                          data.language.includes(defaultLanguage),
                        )
                        if (defaultLang) {
                          return (
                            <div
                              key={index}
                              dangerouslySetInnerHTML={{ __html: defaultLang?.value }}
                            />
                          )
                        }
                      } else {
                        const relevantValue = dataval.HEADING.find((data) =>
                          data.value.includes(search),
                        )

                        if (relevantValue) {
                          return (
                            <div
                              key={index}
                              dangerouslySetInnerHTML={{ __html: relevantValue?.value }}
                            />
                          )
                        } else {
                          const defaultLang = dataval.HEADING.find((data) =>
                            data.language.includes(defaultLanguage),
                          )
                          if (defaultLang) {
                            return (
                              <div
                                key={index}
                                dangerouslySetInnerHTML={{ __html: defaultLang?.value }}
                              />
                            )
                          }
                        }
                      }
                      return null // Or some fallback UI
                    })}
                </CCardTitle>
                <CCardText className="CardTextSmall">
                  {item?.tags?.map((data, index) => (
                    <small
                      key={index}
                      className="text-medium-emphasis languagetags"
                    >{`#${data} `}</small>
                  ))}
                </CCardText>
                <CCardText>
                  <small className="text-medium-emphasis">{item?.createdDate || ''}</small>
                </CCardText>
                <div className="d-flex justify-content-end   align-items-end text-white">
                  <CButton
                    className=" text-white"
                    style={{ marginTop: 5, backgroundColor: '#73cde4', borderColor: '#73cde4' }}
                  >
                    Read more.....
                  </CButton>
                </div>
                <div className="d-flex mt-3 justify-content-end   align-items-end text-black">
                  <FaEye />
                  &ensp;
                  {item?.views}
                </div>
              </div>
            </div>
          </CCardBody>
        </CCard>
      ),
    },
  ]
  useEffect(() => {
    if (statusCode) {
      setMessageStatus(true)
    }
  }, [statusCode])
  return (
    <>
      <CContainer>
        <CRow>
          <CCol className="d-flex justify-content-center">
            <CNavbarBrand href="/" className="py-2">
              <CRow>
                <div style={{ maxWidth: '400px', margin: '0 auto' }}>
                  {/* Adjust maxWidth and margin as needed for your layout */}
                  <img
                    style={{ height: 'auto', width: '100%', maxWidth: '100%' }}
                    src={
                      'https://publicfilesblog.s3.ap-south-1.amazonaws.com/Color+logo+with+background.png'
                    }
                    alt="Your Image Alt Text"
                  />
                </div>
              </CRow>
            </CNavbarBrand>
          </CCol>
        </CRow>

        <CRow>
          <CCol xs={24} md={4} lg={4}>
            <CCol className="order-md-2 order-lg-2 order-xl-2 d-none d-lg-block">
              <SearchBar
                handleSearch={handleSearch}
                className="order-md-2 order-lg-2 order-xl-2 d-none d-lg-block"
              />
            </CCol>

            <CCard className="order-md-1 order-lg-1 order-xl-1">
              <CCardBody>
                <CCardTitle>Sign Up for Our Newsletter</CCardTitle>
                <CCardText>Get notified about new posts.</CCardText>
                <div className="mb-3">
                  <CRow>
                    <CCol xs={12} sm={8} md={6} lg={4} xl={3}>
                      <GoogleLogin
                        size="medium"
                        onSuccess={(credentialResponse) => {
                          handleLogin(credentialResponse, true)
                        }}
                        onError={() => {
                          //
                        }}
                      />
                    </CCol>
                  </CRow>
                </div>
              </CCardBody>
            </CCard>
          </CCol>
          {/* show the search bar on small screens */}
          <CCol xs={24} md={8} className="order-md-2 order-lg-2 order-xl-2 d-lg-none d-block">
            <SearchBar handleSearch={handleSearch} />
          </CCol>
          <CCol xs={12} md={8}>
            <div className="pagination-container mt-3">
              {scannedNodes.length ? (
                <span>
                  Page:{' '}
                  {arrayData.map((_, index) => (
                    <button
                      key={index}
                      type="button"
                      style={{
                        fontWeight: pagination.state.page === index ? 'bold' : 'normal',
                      }}
                      onClick={() => pagination.fns.onSetPage(index)}
                    >
                      {index + 1}
                    </button>
                  ))}
                </span>
              ) : (
                <>
                  <span>
                    <strong>Sorry Results Not Found !</strong>
                  </span>
                </>
              )}
            </div>
            <div className="article-preview-container" style={{ overflowX: 'hidden' }}>
              <TableComponent
                statusPagination={false}
                data={{ nodes }}
                columns={COLUMNS}
                page={Math.ceil(TotalScannedCount / 10)}
              />
            </div>
          </CCol>
        </CRow>
      </CContainer>

      <CModal
        visible={modalMessage}
        onClose={() => {
          setMessageStatus(false)
          dispatch({
            type: 'user/createIntialize',
          })
        }}
      >
        <CModalHeader>
          <CCol className="d-flex justify-content-center">
            {statusCode === 202 && <FaExclamationCircle style={{ color: 'red' }} />}

            {statusCode === 200 && <FaCheckCircle style={{ color: 'green' }} />}
          </CCol>
        </CModalHeader>
        <CModalBody>
          {statusCode === 200 && (
            <CCol className="d-flex justify-content-center">
              <strong>You have successfully signed up for the newsletter</strong>
            </CCol>
          )}
          {statusCode === 202 && (
            <CCol className="d-flex justify-content-center">
              <strong>You have already signed up for our newsletter</strong>
            </CCol>
          )}
          {statusCode === 500 && (
            <div style={{ color: 'red' }}>
              <FaExclamationCircle /> <p>There was an error signing up. Please try again.</p>
            </div>
          )}
        </CModalBody>
      </CModal>
    </>
  )
}

export default HomeView
