import React, { Component, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'
import HomeView from './views/userView/HomeView'
import { useSelector } from 'react-redux'
import LoaderComponent from './components/loading/spinner'
import getMyIp from './customHook.js'
import useGetIp from './customHook.js'
import PostView from './views/postview'
import UpdatedComponent from './HOC/privateComponent'
import { AppContent } from './components'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayoutNew = React.lazy(() => import('./layout/DefaultLayout'))

const DefaultLayout = UpdatedComponent(DefaultLayoutNew)

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

function App() {
  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <LoaderComponent />

        <Routes>
          <Route path="/" name="HomePage" element={<HomeView />} />
          <Route exact path="/posts/:id" name="HomePage" element={<PostView />} />
          <Route exact path="/login" name="Login Page" element={<Login />} />
          <Route exact path="/register" name="Register Page" element={<Register />} />
          <Route exact path="*" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
          <Route exact path="/admin/*" name="AdminLayout" element={<DefaultLayout />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
