import { useSelector } from 'react-redux'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { jwtDecode } from 'jwt-decode'

const UpdatedComponent = (OriginalComponent) => {
  function NewComponent(props) {
    const navigate = useNavigate()
    const token = useSelector((state) => state?.login?.accessToken)

    if (token && jwtDecode(token).email === process.env['REACT_APP_ADMIN_EMAIL']) {
      return <OriginalComponent />
    } else {
      navigate('/login') // Redirect to the '/login' route
      return null // or any other UI if needed before redirection
    }
  }
  return NewComponent
}
export default UpdatedComponent
