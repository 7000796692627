import { call, put, takeLatest, delay } from 'redux-saga/effects'
import { endFetch, startFetch } from '../common/reducer'
import { searchSuccess, sear } from './reducer'
import axios from 'axios'
import { config } from 'src/config'
import { tagsSuccess } from '../tags/reducer'

function* searchHandler({ payload }) {
  yield put(startFetch())
  const { url } = checkSearch(payload.type)
  try {
    const tags = yield call(axios.get, `${url}/?tagName=${payload.searchData}`)
    yield put(tagsSuccess(tags.data))
    yield put(endFetch())
  } catch (error) {
    yield put(endFetch())
  }
}

const checkSearch = (type) => {
  switch (type) {
    case 'TAGS':
      return {
        url: config.GET_TAGS,
      }
  }
}

function* commonSaga() {
  yield takeLatest('common/search', searchHandler)
}

export default commonSaga
