import { createSlice } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

export const tagSlice = createSlice({
  name: 'tags',
  initialState: {
    tagLists: [],
    tagCreateStatus: null,
  },
  reducers: {
    tagsSuccess: (state, action) => {
      state.tagLists = action.payload
    },
    tagsCreateSuccess: (state) => {
      state.tagCreateStatus = true
    },
    tagsCreateFailure: (state) => {
      state.tagCreateStatus = false
    },
    tagsCreateIntial: (state) => {
      state.tagCreateStatus = null
    },

    tagsFailure: (state, action) => {
      state.tagLists = []
    },
  },
})

export const { tagsSuccess, tagsFailure, fetchTags, tagsCreateFailure, tagsCreateSuccess } =
  tagSlice.actions
export default tagSlice.reducer
