import React from 'react'
import 'rsuite/dist/rsuite.min.css'
import HashLoader from 'react-spinners/HashLoader'

import { useSelector } from 'react-redux'

const LoaderComponent = () => {
  const isLoading = useSelector((state) => state?.common?.isLoading)

  return (
    isLoading && (
      <>
        <HashLoader
          color={'#3c4b64'}
          loading={isLoading}
          aria-label="Loading Spinner"
          data-testid="loader"
          className="loader"
        />
      </>
    )
  )
}

export default LoaderComponent
