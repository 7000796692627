export const languages = [
  {
    id: 1,
    language: 'tm',
    langCode: 'TAMIL',
  },
  {
    id: 2,
    language: 'eng',
    langCode: 'ENGLISH',
  },
  {
    id: 3,
    language: 'sh',
    langCode: 'SINHALA',
  },
]

export const languagesDropdown = [
  {
    id: 1,
    language: 'Tamil',
    langCode: 'TAMIL',
  },
  {
    id: 2,
    language: 'English',
    langCode: 'ENGLISH',
  },
  {
    id: 3,
    language: 'Sinhala',
    langCode: 'SINHALA',
  },
]
