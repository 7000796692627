import createSagaMiddleware from 'redux-saga'
import { combineReducers, configureStore } from '@reduxjs/toolkit'
import commonReducer from './sagas/common/reducer'
import tagsReducer from './sagas/tags/reducer'
import rootSaga from './sagas/rootSaga'
import storage from 'redux-persist/lib/storage'
import { persistReducer, persistStore } from 'redux-persist'
import loginReducer from './sagas/login/reducer'
import postReducer from './sagas/posts/reducer'
const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, loginReducer)

const saga = createSagaMiddleware()
const store = configureStore({
  reducer: { common: commonReducer, tags: tagsReducer, login: persistedReducer, post: postReducer },
  middleware: [saga],
})

const persistor = persistStore(store)

saga.run(rootSaga)

export { persistor, store }
