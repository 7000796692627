export const searchFunc = (data) => ({
  type: 'common/search',
  payload: {
    type: 'TAGS',
    searchData: data,
  },
})

export const login = (data) => ({
  type: 'login/registerUser',
  payload: data,
})

export const signUp = (data) => ({
  type: 'user/register',
  payload: data,
})

export const fetchPosts = (data) => ({
  type: 'post/fetchPost',
  payload: data,
})

export const loginAdmin = (data) => ({
  type: 'admin/login',
  payload: data,
})

export const getAdminPosts = (data) => ({
  type: 'posts/adminPost',
  payload: data,
})

export const approvePost = (data) => ({
  type: 'posts/approve',
  payload: data,
})

export const fetchPostById = (data, params) => ({
  type: 'posts/fetchPostById',
  payload: data,
  params,
})
