// BlogPost.js
import React, { useEffect, useState } from 'react'
import { Dropdown } from 'antd'
import {
  CContainer,
  CRow,
  CCol,
  CCard,
  CCardHeader,
  CCardBody,
  CCardFooter,
  CLink,
  CCardTitle,
  CButton,
  CSidebar,
  CSidebarNav,
  CSidebarBrand,
  CNavItem,
  CNavTitle,
  CBadge,
  CNavGroup,
  CDropdown,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownItem,
  CDropdownDivider,
} from '@coreui/react'
import { FaCheckCircle, FaCode, FaHome, FaRedo, FaArrowUp } from 'react-icons/fa'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { solarizedlight } from 'react-syntax-highlighter/dist/esm/styles/prism'
import CodeViewComponent from '../Subcomponents/CodeViewComponent'
import parse from 'html-react-parser'
import { languages, languagesDropdown } from 'src/utils/languages'

import { useNavigate } from 'react-router-dom'

// You can add your own styling in a separate CSS file or use inline styles

const BlogPost = ({ post, handleBack, type, tags, createdDate, keyPoints, handleLanguage }) => {
  const iconStyle = { color: 'green', marginRight: '5px' }
  const [language, setLanguage] = useState('ENGLISH')
  const navigate = useNavigate()
  const [showButton, setShowButton] = useState(true)
  const onScroll = () => {
    let pixelsFromTop = window.scrollY
    let documentHeight = document.body.clientHeight
    let windowHeight = window.innerHeight
    let difference = documentHeight - windowHeight
    let percentage = (100 * pixelsFromTop) / difference

    // Scroll to top button logic
    window.scrollY > 500 ? setShowButton(true) : setShowButton(false)
  }

  useEffect(() => {
    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [onScroll])

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <CContainer style={{ overflow: 'hidden' }} className="mt-3">
      <CRow>
        <CCol md={2} lg={2} xs={24} className="  ">
          {keyPoints && keyPoints.length > 1 && (
            <div className="py-4  contentPagesidebar d-none d-lg-block ">
              {keyPoints
                ?.filter((val, index) => index !== 0)
                .map((data, index) => (
                  <div
                    key={index}
                    style={{ cursor: 'pointer' }}
                    // className="text-primary"
                    onClick={() => {
                      const element = document.getElementById(data?.['HEADING']?.[0]?.value || '')
                      if (element) {
                        element.scrollIntoView({ behavior: 'smooth' })
                      }
                    }}
                  >
                    {parse(data?.['HEADING']?.[0]?.value || '')}
                  </div>
                ))}
            </div>
          )}
        </CCol>

        <CCol md={10} xs={12} lg={10}>
          <CCard>
            <CCardHeader className="bg-light d-flex justify-content-between align-items-center gap-2 ">
              <div className="posted-by-info">
                Posted By :{' '}
                <a
                  href="https://www.linkedin.com/in/seralagan-mathusan-75a437158/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Seralagan Mathusan
                </a>
                <small className="text-muted"> on {createdDate ? createdDate : ''}</small>
              </div>

              {/* <select
                aria-label="Default select example"
                onChange={(e) => handleLanguage(e.target.value)}
              >
                {languagesDropdown.map((data) => {
                  if (data.langCode === 'ENGLISH') {
                    return (
                      <option selected key={data.langCode} value={data.langCode}>
                        {data.language}
                      </option>
                    )
                  } else {
                    return (
                      <option key={data.langCode} value={data.langCode}>
                        {data.language}
                      </option>
                    )
                  }
                })}
              </select> */}

              {keyPoints && keyPoints.length > 1 && (
                <CDropdown
                  direction="center"
                  className="align-self-start  d-lg-none d-block CDropdown "
                >
                  <CDropdownToggle color="secondary">Key Points </CDropdownToggle>
                  <CDropdownMenu className="contentPagesidebar">
                    {keyPoints
                      ?.filter((val, index) => index !== 0)
                      .map((data, index) => (
                        <CDropdownItem
                          // className="contentPagesidebar"
                          key={index}
                          style={{ cursor: 'pointer', color: 'white' }}
                          // className="text-primary"
                          onClick={() => {
                            const element = document.getElementById(
                              data?.['HEADING']?.[0]?.value || '',
                            )
                            if (element) {
                              element.scrollIntoView({ behavior: 'smooth' })
                            }
                          }}
                        >
                          {' '}
                          {parse(data?.['HEADING']?.[0]?.value || '')}
                        </CDropdownItem>
                      ))}
                  </CDropdownMenu>
                </CDropdown>
              )}

              <CButton
                color="secondary"
                onClick={() => {
                  if (type === 'ADMIN') {
                    handleBack(false)
                  } else {
                    navigate('/')
                  }
                }}
                className="align-self-start"
              >
                Back
              </CButton>

              {/* <CSidebar className="CSidebar">
                <CSidebarNav>
                  <CNavGroup toggler="Nav dropdown">
                    <CNavItem href="#">Nav dropdown item</CNavItem>
                    <CNavItem href="#">Nav dropdown item</CNavItem>
                  </CNavGroup>
                </CSidebarNav>
              </CSidebar> */}
            </CCardHeader>
            <CRow className="tag-row mt-3">
              {tags ? (
                tags.map((data, index) => (
                  <CCol xs="auto" key={index} className="tag-col">
                    <span style={{ fontSize: '12px' }}>
                      <strong>&ensp;#{data}</strong>
                    </span>
                  </CCol>
                ))
              ) : (
                <></>
              )}
            </CRow>
            {post.map((val) => {
              return (
                <>
                  {Object.keys(val)[0] === 'CODE' && (
                    <>
                      <CCol xs={12}>
                        <CCardBody>
                          <CodeViewComponent data={val[`${Object.keys(val)[0]}`]} />
                        </CCardBody>
                      </CCol>
                    </>
                  )}

                  {Object.keys(val)[0] === 'TEXT' && (
                    <>
                      <CCol xs={12}>
                        <CCardBody className="text-preview">
                          {parse(
                            val[`${Object.keys(val)[0]}`].find((data) => data.language === language)
                              ?.value || '',
                          )}
                        </CCardBody>
                      </CCol>
                    </>
                  )}
                  {Object.keys(val)[0] === 'HEADING' && (
                    <>
                      <CCol
                        xs={12}
                        id={
                          val[`${Object.keys(val)[0]}`].find((data) => data.language === language)
                            ?.value || ''
                        }
                      >
                        <CCardBody className="heading-preview">
                          {parse(
                            val[`${Object.keys(val)[0]}`].find((data) => data.language === language)
                              ?.value || '',
                          )}
                        </CCardBody>
                      </CCol>
                    </>
                  )}
                  {Object.keys(val)[0] === 'IMAGE' && (
                    <>
                      <CCol xs={12}>
                        <CCardBody className="image-preview">
                          {typeof val[`${Object.keys(val)[0]}`] === 'string' ? (
                            <img src={val[`${Object.keys(val)[0]}`]} />
                          ) : (
                            <img src={URL.createObjectURL(val[`${Object.keys(val)[0]}`])} />
                          )}
                        </CCardBody>
                      </CCol>
                    </>
                  )}
                </>
              )
            })}
            <CCardBody></CCardBody>

            {/* <CCardFooter className="text-muted">More from Orion3D [...]</CCardFooter> */}
          </CCard>
        </CCol>
        {/* <CCol md={2} className="mt-3">
       
          <div className="p-3 mb-3 bg-light rounded">
            <h6 className="font-weight-bold text-primary">CodeCryst</h6>
            <p>© {new Date().getFullYear()} All rights reserved.</p>
          </div>
         
        </CCol> */}
      </CRow>
      <FaArrowUp className={showButton ? 'showButton' : 'hidden'} onClick={scrollToTop} />
    </CContainer>
  )
}

export default BlogPost
