import React, { useEffect, useState } from 'react'
import BlogPost from '../posts/PostPreview/preview'
import { fetchPostById } from 'src/sagas/actions/common'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
const PostView = () => {
  const { id } = useParams()
  const dispatch = useDispatch()
  const [post, setPost] = useState([])
  const getPostByID = useSelector((state) => state?.post?.getPostByID)
  const getByIdStatus = useSelector((state) => state?.post?.getByIdStatus)

  useEffect(() => {
    if (getByIdStatus) {
      setPost(getPostByID?.post)
    }
  }, [getPostByID])

  useEffect(() => {
    dispatch(
      fetchPostById(
        { id, lastEvaluatedKey: '', search: '' },
        { language: 'ENGLISH', type: 'CLIENT' },
      ),
    )
  }, [])
  const handleLanguage = (language) => {
    dispatch(
      fetchPostById(
        { id, lastEvaluatedKey: '', search: '' },
        { language: language, type: 'CLIENT' },
      ),
    )
  }
  return (
    <>
      <BlogPost
        handleLanguage={handleLanguage}
        post={post}
        createdDate={getPostByID?.createdDate}
        tags={getPostByID?.tags}
        keyPoints={getPostByID?.keyPoints}
      />
    </>
  )
}

export default PostView
