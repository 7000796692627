import React from 'react'

const SearchBar = ({ handleSearch }) => {
  return (
    <div className="search-bar">
      <input onChange={handleSearch} type="text" placeholder="Search..." />
    </div>
  )
}

export default SearchBar
