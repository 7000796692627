import { createSlice } from '@reduxjs/toolkit'

export const postSlice = createSlice({
  name: 'post',
  initialState: {
    postUploadStatus: null,
    searchSuccessStatus: null,
    searchedData: [],
    adminPostSuccess: null,
    adminPostData: [],
    approveStatus: null,
    getByIdStatus: null,
    getPostByID: null,
  },
  reducers: {
    postSuccess: (state, action) => {
      state.postUploadStatus = true
    },

    postFailure: (state, action) => {
      state.postUploadStatus = false
    },
    postIntialize: (state, action) => {
      state.postUploadStatus = null
    },

    searchPostSuccess: (state, action) => {
      state.searchSuccessStatus = true
      state.searchedData = action.payload
    },
    searchPostFailure: (state, action) => {
      state.searchSuccessStatus = false
      state.searchedData = []
    },

    approveSuccess: (state, action) => {
      state.approveStatus = true
    },
    approveFailure: (state, action) => {
      state.approveStatus = false
    },

    approveStart: (state, action) => {
      state.approveStatus = null
    },

    adminPostSuccess: (state, action) => {
      state.adminPostSuccess = true
      state.adminPostData = action.payload
    },
    adminPostFailure: (state, action) => {
      state.adminPostSuccess = true
      state.adminPostData = []
    },
    fetchByIdSuccess: (state, action) => {
      state.getByIdStatus = true
      state.getPostByID = action.payload
    },
    fetchByIdFailure: (state, action) => {
      state.getByIdStatus = false
      state.getPostByID = null
    },
    fetchByIdInitialize: (state, action) => {
      state.getByIdStatus = null
      state.getPostByID = null
    },
  },
})

export const { postSuccess, postFailure, postIntialize } = postSlice.actions
export default postSlice.reducer
