const ENV_NAME = 'REACT_APP_'

const BASE_URL = process.env[`${ENV_NAME}BASE_URL`]

export const config = {
  GET_TAGS: `${BASE_URL}/tags`,
  REGISTER_USER: `${BASE_URL}/user`,
  GET_PRESIGNED_URL: `${BASE_URL}/getSignedUrl`,
  CREATE_POST: `${BASE_URL}/posts`,
  SEARCH_POST: `${BASE_URL}/posts/search`,
  SEARCH_POST_ADMIN: `${BASE_URL}/posts/getAll`,
  APPROVE_POST: `${BASE_URL}/posts/approve`,
  GET_POST_BY_ID: `${BASE_URL}/post`,
  UPDATE_POST: `${BASE_URL}/posts/updatePost`,
  ADMIN_CREATE: `${BASE_URL}/user/admin/signUp`,
  LOGIN: `${BASE_URL}/user/admin/siginin`,
}
