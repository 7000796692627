import { call, put, takeLatest, delay } from 'redux-saga/effects'
import { endFetch, startFetch } from '../common/reducer'
import { tagsSuccess, tagsFailure, tagsCreateFailure, tagsCreateSuccess } from './reducer'
import axios from 'axios'
import { config } from 'src/config'

function* getTags({ payload }) {
  yield put(startFetch())
  try {
    const queryString = `search=${payload.search}&lastEvaluatedKey=${payload.lastEvaluatedKey}`
    const tags = yield call(axios.get, `${config.GET_TAGS}?${queryString}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    })
    yield put(tagsSuccess(tags?.data))
    yield put(endFetch())
  } catch (error) {
    yield put(endFetch())
    yield put(tagsFailure())
  }
}

function* createTags({ payload }) {
  yield put(startFetch())
  try {
    const tags = yield call(
      axios.post,
      config.GET_TAGS,
      { tagName: payload },
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      },
    )
    yield put(tagsCreateSuccess())
    yield put(endFetch())
  } catch (error) {
    yield put(tagsCreateFailure())
    yield put(endFetch())
  }
}

function* tagsSaga() {
  yield takeLatest('tags/fetchTags', getTags)
  yield takeLatest('tags/create', createTags)
}

export default tagsSaga
