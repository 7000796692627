import { createSlice } from '@reduxjs/toolkit'

export const commonSlice = createSlice({
  name: 'common',
  initialState: {
    isLoading: false,
    searchResults: [],
    adminStatusCode: null,
    userStatus: null,
    adminStatusCode: null,
    adminIsCreated: null,
    adminEmail: null,
    adminName: null,
    adminUserId: null,
    userStatus: null,
    adminFinalCreatedStatusCode: null,
  },
  reducers: {
    adminNonPersist: (state, action) => {
      state.adminStatusCode = action?.payload?.statusCode
      state.userStatus = action?.payload?.userData?.userStatus
      state.adminEmail = action?.payload?.userData?.email
      state.adminName = action?.payload?.userData?.name
      state.adminUserId = action?.payload?.id
    },
    adminNonStart: (state) => {
      state.adminStatusCode = null
      state.userStatus = null
      state.adminEmail = null
      state.adminName = null
      state.adminUserId = null
    },
    adminNonFail: (state) => {
      state.adminStatusCode = 400
      state.userStatus = null
      state.adminEmail = null
      state.adminName = null
      state.adminUserId = null
    },
    startFetch: (state, action) => {
      state.isLoading = true
    },
    endFetch: (state, action) => {
      state.isLoading = false
    },
    adminFinalCreatedSuccess: (state) => {
      state.adminFinalCreatedStatusCode = 200
    },
    adminFinalCreatedFailure: (state) => {
      state.adminFinalCreatedStatusCode = 400
    },
    adminFinalCreatedStart: (state) => {
      state.adminFinalCreatedStatusCode = null
    },
  },
})

export const { startFetch, endFetch, searchSuccess } = commonSlice.actions
export default commonSlice.reducer
