import { CCol, CRow } from '@coreui/react'
import React, { useMemo } from 'react'
import { CompactTable } from '@table-library/react-table-library/compact'
import { useSort } from '@table-library/react-table-library/sort'
import { usePagination } from '@table-library/react-table-library/pagination'
import { useMeasure } from '@uidotdev/usehooks'

const TableComponent = ({ data, columns, onPaginationChange, page, statusPagination }) => {
  const arrayData = useMemo(() => {
    const array = []
    for (let i = 0; i <= page - 1; i++) {
      array.push(i + 1)
    }

    return array
  }, [page])
  const pagination = usePagination(data, {
    state: {
      page: 0,
      size: 10,
    },
    onChange: onPaginationChange,
  })
  return (
    <CRow>
      <CCol xs={48} sm={24} lg={12}>
        <CompactTable columns={columns} data={data} />
      </CCol>

      {statusPagination && (
        <span>
          Page:{' '}
          {arrayData.map((_, index) => (
            <button
              key={index}
              type="button"
              style={{
                fontWeight: pagination.state.page === index ? 'bold' : 'normal',
              }}
              onClick={() => pagination.fns.onSetPage(index)}
            >
              {index + 1}
            </button>
          ))}
        </span>
      )}
    </CRow>
  )
}

export default TableComponent
