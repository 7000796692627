import { createSlice } from '@reduxjs/toolkit'

export const loginSlice = createSlice({
  name: 'user',
  initialState: {
    isCreated: false,
    email: '',
    name: '',
    statusCode: null,
    userIp: null,
    accessToken: null,
    isLogin: null,
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.accessToken = action.payload
      state.isLogin = true
      localStorage.setItem('token', action.payload)
    },
    loginFailure: (state) => {
      state.accessToken = null
      state.isLogin = false
      localStorage.setItem('token', '')
    },
    loginInitial: (state) => {
      state.accessToken = null
      state.isLogin = null
      localStorage.setItem('token', '')
    },
    logout: (state) => {
      state.isLogin = false
      state.accessToken = null
      state.name = null
      state.email = null
      state.isCreated = null
      localStorage.setItem('token', '')
    },
    createSuccess: (state, action) => {
      state.isCreated = true
      state.email = action.payload.email
      state.name = action.payload.name
      state.statusCode = action.payload.statusCode
    },

    createIntialize: (state, action) => {
      state.isCreated = null
      state.email = null
      state.name = null
      state.statusCode = null
    },
    createFailure: (state, action) => {
      state.isCreated = true
      state.statusCode = 400
      state.email = null
      state.name = null
    },
    setUserIp: (state, action) => {
      state.userIp = action.payload
    },
  },
})

export const { LoginSuccess, loginFailure } = loginSlice.actions
export default loginSlice.reducer
