import { config } from 'src/config'
import { tagsFailure } from '../tags/reducer'
import { loginFailure, LoginSuccess } from './reducer'
import { endFetch, startFetch } from '../common/reducer'
import { call, put, takeLatest } from 'redux-saga/effects'
import axios from 'axios'
import { func } from 'prop-types'

function* login({ payload }) {
  yield put(startFetch())
  yield put({
    type: 'commom/adminNonStart',
  })
  try {
    const response = yield call(axios.post, config.REGISTER_USER, payload)

    if (payload.type === 'ADMIN') {
      yield put({
        type: 'common/adminNonPersist',
        payload: { ...response.data, statusCode: response.status },
      })
    } else {
      yield put({
        type: 'user/createSuccess',
        payload: { ...response.data, statusCode: response.status },
      })
    }

    yield put(endFetch())
  } catch (error) {
    if (payload.type === 'ADMIN') {
      yield put({
        type: 'common/adminNonFail',
      })
    } else {
      yield put({
        type: 'user/createFailure',
        payload: { data: { ...payload }, error: error },
      })
    }

    yield put(endFetch())
  }
}
function* regsiterAdmin({ payload }) {
  yield put(startFetch())
  try {
    yield call(axios.post, config.ADMIN_CREATE, payload)
    yield put({
      type: 'common/adminFinalCreatedSuccess',
    })
  } catch (error) {
    yield put({
      type: 'common/adminFinalCreatedFailure',
    })
  }
}

function* setUserIP() {
  yield put(startFetch())
  try {
    yield put({
      type: 'user/setUserIp',
      payload: '112.135.19.123.MMMathusan',
    })
    yield put(endFetch())
  } catch (error) {}
}
function* loginUser({ payload }) {
  yield put(startFetch())
  const response = yield call(axios.post, config.LOGIN, payload)

  yield put({
    type: 'user/loginSuccess',
    payload: response?.data?.response?.IdToken,
  })
  yield put(endFetch())
}

function* loginSaga() {
  yield takeLatest('login/registerUser', login)
  yield takeLatest('user/register', regsiterAdmin)
  yield takeLatest('user/settingIp', setUserIP)
  yield takeLatest('admin/login', loginUser)
}

export default loginSaga
